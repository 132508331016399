import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

// Error Component
const Error = ({ message }) => (
  <div className="flex items-center justify-center w-full h-full">
    <p className="text-red-500 text-lg">{message}</p>
  </div>
);

export const People = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [peopleList, setPeopleList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate fetching data
    const fetchData = async () => {
      try {
        // Replace with your API endpoint
        const response = await fetch('/data/people.json');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setPeopleList(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredPeople = peopleList.filter(person => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    return (
      person.name.toLowerCase().includes(lowerCaseSearch) ||
      person.title.toLowerCase().includes(lowerCaseSearch) ||
      person.description.toLowerCase().includes(lowerCaseSearch) ||
      (person.facebook && person.facebook.toLowerCase().includes(lowerCaseSearch)) ||
      (person.twitter && person.twitter.toLowerCase().includes(lowerCaseSearch)) ||
      (person.instagram && person.instagram.toLowerCase().includes(lowerCaseSearch)) ||
      (person.linkedIn && person.linkedIn.toLowerCase().includes(lowerCaseSearch))
    );
  });

  const renderSection = (sectionTitle, sectionType) => (
    <div className="mb-12">
      <h2 className="text-2xl text-sky-700 font-bold mb-6 text-center">{sectionTitle}</h2>
      <div className="flex flex-wrap justify-center">
        {filteredPeople
          .filter(person => person.type === sectionType)
          .map((person, index) => (
            <ProfileCard key={index} {...person} />
          ))}
      </div>
    </div>
  );

  return (
    <div className="max-w-screen-lg mx-auto mb-24 h-full mt-2">
      {/* Search Input */}
      {/* <div className="mx-4 sm:mx-6 md:mx-8 lg:mx-12">
        <input
          type="text"
          placeholder="Search..."
          className="p-2 mb-4 border border-sky-700 shadow-md rounded-md w-full" // Adjusted to take full width
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div> */}

      {/* Conditional Rendering */}
      {loading ? (
        <div className="overflow-y-scroll h-[calc(100vh-150px)]">
          <Loader />
        </div>
      ) : error ? (
        <div className="overflow-y-scroll h-[calc(100vh-150px)]">
          <Error message={`Error loading people: ${error}`} />
        </div>
      ) : filteredPeople.length > 0 ? (
        <div className="overflow-y-scroll h-[calc(100vh-150px)]">
          {renderSection('Faculty', 'Faculty')}
          {renderSection('Guest Faculty', 'Guest Faculty')}
          {renderSection('Staff', 'Staff')}
        </div>
      ) : (
        <div className="overflow-y-scroll h-[calc(100vh-150px)]">
          <p className="text-gray-700 text-lg text-center">No people found.</p>
        </div>
      )}
    </div>
  );
};

const ProfileCard = ({ name, image, title, description, facebook, instagram, twitter, linkedIn, link }) => {
  const redirectToLink = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <div className="max-w-xs mx-2 my-5 bg-gray-200 shadow-lg rounded-lg overflow-hidden flex flex-col justify-between">
      <div>
        <div className="flex items-center justify-center mt-8">
          <img
            className="w-24 h-24 object-cover rounded-full"
            src={image || 'default_image_url'}
            alt={name}
          />
        </div>

        <div className="px-6 py-4">
          <div className="text-sky-700 font-bold text-xl mb-2">{name}</div>
          <p className="text-sky-700 text-base mb-1">{title}</p>
          <p className="text-sky-900 text-base">{description}</p>
        </div>
      </div>

      <div className="px-6 pb-4 mt-auto flex items-center justify-between">
        <div>
          {facebook && (
            <a href={facebook} className="text-sky-700 hover:text-sky-700 mr-2">
              <i className="fab fa-facebook"></i>
            </a>
          )}
          {twitter && (
            <a href={twitter} className="text-sky-700 hover:text-sky-700 mr-2">
              <i className="fab fa-twitter"></i>
            </a>
          )}
          {instagram && (
            <a href={instagram} className="text-sky-700 hover:text-sky-700 mr-2">
              <i className="fab fa-instagram"></i>
            </a>
          )}
          {linkedIn && (
            <a href={linkedIn} className="text-sky-700 hover:text-sky-700">
              <i className="fab fa-linkedin"></i>
            </a>
          )}
        </div>

        {link && (
          <button
            className="bg-sky-700 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded"
            onClick={redirectToLink}
          >
            Read More
          </button>
        )}
      </div>
    </div>
  );
};

export default People;
