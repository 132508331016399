import React, { useState, useEffect } from 'react';

// Loader Component
const Loader = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="w-8 h-8 border-4 border-t-4 border-gray-500 border-solid rounded-full animate-spin"></div>
  </div>
);

export const Jobs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [jobList, setJobList] = useState([]); // State for storing job data
  const [loading, setLoading] = useState(true); // State for loading status

  // Fetch job data from the public folder
  useEffect(() => {
    fetch('/data/job.json')
      .then((response) => response.json())
      .then((data) => {
        setJobList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading job data:', error);
        setLoading(false);
      });
  }, []);

  const filteredJobs = jobList?.filter((job) =>
    job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mx-4 md:mx-20 lg:mx-40 h-screen flex flex-col mt-6 mb-12 relative">
      {/* Search Input */}
      <input
        type="text"
        placeholder="Search for jobs..."
        className="p-2 mb-4 border border-sky-700 shadow-md w-full rounded-md"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="flex-1 overflow-y-auto">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <Loader />
          </div>
        ) : filteredJobs.length === 0 ? (
          <div className="text-center text-gray-700">
            No jobs match your search.
          </div>
        ) : (
          filteredJobs.map((job) => (
            <a
              key={job.id}
              href={job.link}
              target="_blank"
              rel="noopener noreferrer"
              className="block cursor-pointer mb-4 text-left"
            >
              <div className="bg-blue p-4 w-full text-sky-700 shadow-md transition duration-300 hover:shadow-lg rounded-md">
                <h2 className="text-base sm:text-lg lg:text-xl">{job.title}</h2>
              </div>
            </a>
          ))
        )}
      </div>
    </div>
  );
};

export default Jobs;
